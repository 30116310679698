/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:5b9a8da5-8dd6-48d1-8860-e8227e5ffefb",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_8ckdGonXh",
    "aws_user_pools_web_client_id": "6uau14v008gvpl74p269ujv09r",
    "oauth": {}
};


export default awsmobile;
